<template>
	<BACK title="注册" :history="history"></BACK>
	<div class="topDes" @click="goBack">已有账号</div>
	<div class="registerpswBox">
		<div class="registerTishi">未满18周岁的未成年人不允许注册账号</div>
		<div class="registerpswDiv phonediv">
			<input type="text" v-model="mobile" class="registerpswInput phone" placeholder="请输入您的手机号" />
			<img class="inputDel" @click="delInput('mobile')" src="@/assets/images/close_hui.png"/>
		</div>
		
		<div class="registerpswDiv phoneCodeDiv">
			<input type="text" v-model="smscode" class="registerpswInput phoneCode" placeholder="请输入短信验证码" />
			<img class="inputDel" @click="delInput('smscode')"  src="@/assets/images/close_hui.png"/>
			<van-button class="codeBtn" @click="getCode" v-preventReClick="1500">{{codeDes}}</van-button>
		</div>	
		
		<div class="registerName">需6-16位，至少包含数字、字母及下划线的2种</div>
		<div class="registerpswDiv">
			<input type="password" v-model="password" class="registerpswInput psw" placeholder="请输入您的新密码" />
			<img class="inputDel" @click="delInput('password')" src="@/assets/images/close_hui.png"/>
		</div>
		<div class="registerpswDiv comfirmDiv">
			<input type="password" v-model="password2"  class="registerpswInput comfirmpsw" placeholder="请再次输入密码" />
			<img class="inputDel" @click="delInput('password2')" src="@/assets/images/close_hui.png"/>
		</div>
		<div class="registerpswDiv">
			<input type="text" v-model="truename" class="registerpswInput phone" placeholder="请输入您的真实姓名" />
			<img class="inputDel"  @click="delInput('truename')" src="@/assets/images/close_hui.png"/>
		</div>
		<div class="registerpswDiv">
			<input type="text" v-model="idcard" class="registerpswInput phone" placeholder="请输入您的身份证号" />
			<img class="inputDel"  @click="delInput('idcard')" src="@/assets/images/close_hui.png"/>
		</div>
		<div class="registerXYDiv">
			<input class="checkBox" v-model="flag" type="checkbox" name="xy" id="xy" checked ><label for="xy">我已阅读并同意</label>
			<a href="http://img-home.gzfei.com/wap/zixun/qy/yhxy.html" class="blue">《紫菲通行证服务条款》</a>
			<a href="http://img-home.gzfei.com/wap/zixun/qy/yszc.html" class="blue">《紫菲游戏用户隐私协议》</a>
		</div>
		<van-button  class="qdBtn" @click="registerComfirm" v-preventReClick="1500">确定</van-button>
	</div>
	<!--底部菜单-->
	<MENUE current='login'></MENUE>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import BACK from '@/components/back/back.vue'
	import request from '@/service/request'
	import { Toast,Button} from 'vant';
	import MENUE from '@/components/menue/menue.vue';
	
	export default defineComponent({
		name: 'register',
		components: {
			BACK,
			[Button.name]:Button,
			MENUE
		},
		mounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(0deg, #EDEFEB, #EDEFEB);");
		},
		unmounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(#95C5B5, #64A4A4) no-repeat;");
		},
		data(){
			return{
				mobile:'',
				smscode:'',
				password:'',
				password2:'',
				truename:'',
				idcard:'',
				codeDes:"获取验证码",
				flag:true,
				history:''
			}
		},
		created(){
			this.history=this.$route.query.history;
		},
		// directives:{
		// 	  preventReClick:preventReClick
		// },
		methods:{
			goBack(){
				debugger;
				this.$router.replace(this.history);
			},
			getCode(){
				if(this.codeDes!="获取验证码") return;
				if(!this.mobile){
					Toast("请输入手机号");
					return;
				}
				var phoneReg=/^(1[0123456789]{10})$/;
				if(phoneReg.test(this.mobile)){
					request.apiAxios({
						method: 'post',
						url: request.PHONECODE,
						data: {
							mobile:this.mobile
						}
					}).then((res) => {
						if(res.status){
							var len=300;
							Toast.success("发送成功");
							this.timeDes(len);
						}else{
							Toast(res.message)
						}
						
					}).catch((err) => {
						console.log(err);
						debugger;
					})
				}else{
					Toast("请输入正确的手机号码");
				}
				
				
			},
			//注册提交
			registerComfirm(){
				//var regex =/(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?,.]+)$)^[\w~!@#$%^&*?,.]{6,16}$/;
				var regex = /^(?!(\d+|[a-z]+|_+|(?!([\da-z]+|[\d_]+|[a-z_]+)$)[\da-z_]+)$)([\da-z]|[\d_]|[a-z_]){6,16}$/;
				if(!this.mobile||!this.password||!this.password2||!this.smscode||!this.truename||!this.idcard){
					Toast("请完善信息！");
					return;
				}
				var phoneReg=/^(1[0123456789]{10})$/;
				if(!phoneReg.test(this.mobile)){					
					Toast("请输入正确的手机号码！");
					return;
				}
				
				if(this.password.length<6||this.password.length>16||!regex.test(this.password)){
					Toast("需6-16位，至少包含数字、字母及标点符号的2种！");
					return;
				}
				if(this.password!==this.password2){
					Toast("两次密码不一致！");
					return;
				}
				if(!this.flag){
					Toast("请勾选协议！");
					return;
				}
				request.apiAxios({
					method: 'post',
					url: request.PHONEREGISTER,
					data: {
						mobile:this.mobile,
						password:this.password,
						smscode:this.smscode,
						truename:this.truename,
						idcard:this.idcard							
					}
				}).then((res) => {
					if(res.status){
						console.log(res,'注册成功');
						localStorage.setItem('token',res.data.token);
						localStorage.setItem('userInfo',JSON.stringify(res.data));
						this.$store.dispatch('setToken',res.token);
						Toast("登录成功！");
						this.$router.replace('/');
					}else{
						Toast(res.message);
					}						
				}).catch((err) => {
					console.log(err);						
				})
			},
			delInput(name){
				this[name]="";
			},
			timeDes(len){
				if(len<=0){
					this.codeDes="获取验证码"
				}else{
					console.log(len);
					this.timer=setTimeout(()=>{
						len--;
						this.codeDes=len+'s';
						this.timeDes(len)
					},1000)					
				}
			}
		},
		beforeUnmount() {
		  clearInterval(this.timer);
		}
		//mounted:function(){ }
	});
</script>

<style lang="less" scoped="scoped">
	.topDes{
		position: fixed;
		top: 0.25rem;
		left: 0.76rem;
		color:#73D1C7;
		font-size: 0.3rem;
		display: inline-block;
		z-index:10000;
	}
	.registerTishi{
		font-size:0.25rem;
		color:#ED5859;
		margin-bottom:0.13rem;
	}
	.registerpswBox {
		padding: 0.17rem 0.42rem;
		.phonediv{
			margin-bottom:0.6rem;
		}
		.registerName{
			color:#20636C;
			font-size:0.3rem;
			padding:0.15rem 0;
		}
		.codeBtn{
			width: 2.22rem;
			height: 0.9rem;
			background: #73D1C7;
			border-radius: 0.14rem;
			flex:none;
			line-height:0.9rem;
		}
		.comfirmDiv{
			margin-bottom:0.36rem;
		}
		.registerXYDiv{
			color:#20636C;
			margin:0.19rem 0 0.5rem;
			font-size:0.3rem;
			input{vertical-align: middle;}
			label{font-size: 0.2rem;vertical-align: middle}
		}
		.checkBox{
			margin-right:0.15rem;
		}
		
	}

	.registerpswDiv {
		height: 0.9rem;
		background: #FFFEFE;
		border-radius: 0.14rem;
		margin-bottom: 0.04rem;
		display:flex;
		padding-left:0.2rem;
		position:relative;
		input{flex:auto;border:0;font-size: 0.3rem;color:#5D6262;}
		.inputDel{
			width: 0.52rem;
			height: 0.52rem;
			padding:0.13rem;
			position:absolute;
			top:0.2rem;
			right:0.16rem;
		}
		&.phoneCodeDiv{
			.inputDel{
				right:2.38rem;
			}
		}
		// &.phonediv:hover .inputDel,{
		// 	display: block;
		// }
	}
</style>
